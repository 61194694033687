<template>
    <a-modal
        :title="form_data.title"
        :visible="visible"
        @ok="submit"
        :destroyOnClose="destroyOnClose"
        @cancel="handleCancel"
        :width="width"
		:okText="onText"
    >
        <slot name="map"></slot>       
        <!-- <Form
            ref="form"
            @handleSubmit="handleSubmit"
			@handleChange="handleChange"
            :form_data="form_data"
            @tree_select="tree_select"
            @get_select_value="get_select_value"
        >
            <slot :slot="item.name" v-for="item in form_data.list" :name="item.name"></slot>
        </Form> -->
        <div>
            <a-form :form="form" ref="form" @submit="handleSubmit" :layout="form_data.layout ? form_data.layout : 'horizontal'">
                <a-form-item>
                    <div>
                        <div class="column_class">
                            <div>组合名称</div>
                            <div class="column_input">
                                <a-input placeholder="请填写组合名称" v-model="form_data.name"></a-input>
                            </div>
                        </div>
                        <div class="column_class">
                            <div>排序</div>
                            <div class="column_input">
                                <a-input placeholder="请填写组合排序" v-model="form_data.sort"></a-input>
                            </div>
                        </div>
                    </div>
                    <div class="com_box" v-for="(item,key) in form_data_all" :key="key">
                        <div>组合内容{{key + 1}}</div>
                        <div class="column_input input_display">
                            <div class="select_class1">
                                <a-select style="minWidth:350px" placeholder="请选择组合字段" mode="multiple" v-model="item.field">
                                    <a-select-option v-for="vo in combina" :key="vo.key" :title="vo.value" :value="vo.key">{{ vo.value }}</a-select-option>
                                </a-select>
                            </div>
                            <div class="select_class2">
                                <a-select style="minWidth:200px;margin-left: 50px;" :placeholder="!item.operator?'请选择字段组合方式':'请选择'" v-model="item.operator">
                                    <a-select-option v-for="vo in combina_methord" :key="vo.key" :title="vo.value" :value="vo.key">{{ vo.value }}</a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div style="display:flex">   
                            <div class="select_class2">
                                <a-select style="minWidth:200px"  :placeholder="!item.join_operator?'请选择内容组合方式':'请选择'" v-model="item.join_operator">
                                    <a-select-option v-for="vo in combina_methord" :key="vo.key" :title="vo.value" :value="vo.key">{{ vo.value }}</a-select-option>
                                </a-select>
                            </div>
                            <div v-if="form_data_all.length > 1" style="margin-left:20px;color:#1890FF" @click="del_data(key)">删除</div>
                        </div>
                    </div>
                    <div class="btn_box">
                        <a-icon type="plus-circle"  @click="get_form_data_all"/>
                        <span class="btn" @click="get_form_data_all">添加组合内容</span>
                    </div>
                </a-form-item>
            </a-form>
        </div>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
// import Form from './Form'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [String, Number],
            default: 520,
        },
		onText:{
            type: [String],
            default: '确定',
        },
        destroyOnClose: {
            type: Boolean,
            default: true,
        },
        form_data: {
            type: Object,
            default: () => {
                return {
                    list: []
                }
            }
        },
        combina: {
			type: Array,
			default: () => {
				return []
			}
		},
        combina_methord: {
            type:Array,
            default: () => {
                return []
            }
        },
        form_data_all: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    components: {
        // Form,
    },
    beforeCreate() {
			this.form = this.$form.createForm(this, {
				name: 'seo'
			});
	},
    data() {
        return {

        }
    },
    methods: {
        handleCancel() {
            this.$emit('update:visible', false)
            this.$emit('cancle_rule')
        },
        submit() {
            // this.$refs.form.submit();
            let combina_content = [];
            if (!this.form_data.name) {
                message.error('请填写组合名称')
            }
            if (this.form_data_all.length == 0) {
                message.error('请选择组合内容')
            }
            this.form_data_all.forEach((item,key) => {
                if (item.field.length == 0) {
                    message.error('请选择组合内容')
                }
                if (this.form_data_all.length > 1 && !item.join_operator && key != this.form_data_all.length - 1) {
                    message.error('组合内容'+ (key+1) +'请选择内容组合方式')
                }
                combina_content.push({operator:item.operator,field:item.field,join_operator:item.join_operator})
            })
            let combination = {name:this.form_data.name,sort:this.form_data.sort,rule:combina_content}

            this.$emit('handleSubmit',combination)
        },
        handleChange(e) {
            this.$emit('handleChange', e);
        },
        handleFocus(e) {
            this.$emit('handleFocus', e);
        },
        handleSubmit(e) {
            // this.$emit('handleSubmit', e)
            e.preventDefault();
			this.submit();
        },
        tree_select(e) {
            this.$emit('tree_select', e)
        },
        get_select_value(e) {
            this.$emit('get_select_value', e)

        },
        setFieldsValue(data) {
            this.$refs.form.setFieldsValue(data);
        },
        get_form_data_all() {
            this.$emit('get_form_data_all')
        },
        del_data(key) {
            this.$emit('del_data',key)
        }

    },

}
</script>
<style lang="less" scoped>
    .com_box {
        // margin-bottom: 10px;
    }
    .column_class {
        // margin-bottom: 24px;
    }
    .column_input {
        // margin-top: 10px;
    }
    .input_display{
        display: flex;
        margin-bottom: 10px;
    }

    .select_class1{
        width: 400px;
    }
    .select_class2{
        width: 200px;
    }

    .btn_box {
        color: #1890FF;
        .btn {
            margin-left: 5px;
        }
    }
</style>
